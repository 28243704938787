<template>
  <div>
    <MyHeader
      :title="$t('lang.menu_missrecord')"
      :ShowBack="true"
      BackUrl="/query/index"
    />
    <van-list
      v-model="loading"
      :finished="finished"
      :finished-text="$t('lang.listend')"
      :immediate-check="false"
      direction="down"
      @load="onLoad"
    >
      <van-cell
        v-for="mData in lstData"
        :key="mData.id"
        :title="mData.placeName"
        is-link
        :icon="mData.isMiss || mData.isLate || mData.isEarly ? 'warning' : ''"
        :class="
          mData.isMiss
            ? mData.endTime > new Date()
              ? 'isWait'
              : 'isMiss'
            : mData.isLate || mData.isEarly
            ? 'warning'
            : ''
        "
        :to="{
          name: 'PollingPlanDetail',
          params: {
            id: mData.id,
            title: mData.placeName,
            isMiss: mData.isMiss,
            memo: mData.comment,
            isaudit: mData.isAudit,
          },
        }"
      >
        <template slot="label">
          <span
            >巡检时间：{{
              mData.isMiss
                ? "【" +
                  (mData.endTime > new Date()
                    ? $t("lang.notstarted")
                    : $t("lang.miss")) +
                  "】"
                : mData.arriveTime != undefined
                ? $Tools.FormatDate1(mData.arriveTime)
                : ""
            }}</span
          >
          <div style="display: block">
            <span style="display: block"
              >线路/人员：{{
                mData.routeName +
                (mData.inspectorName != undefined
                  ? " " + mData.inspectorName
                  : "")
              }}<br />
              开始时间：{{ $Tools.FormatDate1(mData.startTime) }}<br />
              结束时间：{{ $Tools.FormatDate1(mData.endTime) }}</span
            >
          </div>
        </template>
      </van-cell>
    </van-list>
    <!--div
      v-show="showHint"
      style="margin: 20px; text-align: center; display: block; color: #969799"
    >
      {{ $t("lang.nodata") }}
    </div-->
  </div>
</template>

<style scoped>
.warning .van-cell__left-icon {
  color: #663366;
  font-size: 20px;
}
.warning .van-cell__title {
  color: #663366;
}
.isMiss .van-cell__left-icon {
  color: red;
  font-size: 20px;
}
.isMiss .van-cell__title {
  color: red;
}
.isWait .van-cell__left-icon {
  color: blue;
  font-size: 20px;
}
.isWait .van-cell__title {
  color: blue;
}
</style>

<script>
import MyHeader from "@/components/Header.vue";
import { GetPollingPlan } from "@/api/index.js";
import { getSessionObj } from "@/utils/session.js";

export default {
  components: { MyHeader },
  data() {
    return {
      mDate1: "",
      mDate2: "",
      mCompany: "-1",
      mDept: "-1",
      mLine: "-1",

      lstData: [],
      loading: false,
      finished: false,
      page: 1,
      pagesize: 20,
      //showHint: true,
    };
  },

  mounted() {
    if (this.$route.query.mDate1) {
      let p = this.$route.query;
      this.mDate1 = p.mDate1;
      this.mDate2 = p.mDate2;
      this.mCompany = p.mCompany;
      this.mDept = p.mDept;
      this.mLine = p.mLine;

      this.page = 1;
      this.lstData = [];
      this.Query();
    }
  },

  methods: {
    Query() {
      let that = this;
      that.loading = true;

      GetPollingPlan(
        {
          token: this.$store.getters.getToken,
          startTime: this.mDate1,
          endTime: this.mDate2,
          strCompanyIds: this.mCompany,
          strDepartmentIds: this.mDept,
          strRouteIds: this.mLine,
          strPlaceIds: "",
          strGroupIds: "",
          strInspectorIds: "",
          queryType: 99,
          sort: "asc",
          page: this.page,
          limit: this.pagesize,
        },
        (ret) => {
          that.loading = false;
          if (ret && ret.code == 0) {
            if (ret.count == 0) {
              that.finished = true;
              //that.showHint = false;
            } else {
              //填充
              if (this.page == 1) that.lstData = ret.data;
              else that.lstData.push(...ret.data);
              //this.showHint = that.lstData.length == 0;

              //判断是否全部加载完成
              if (that.lstData.length < ret.count) {
                that.page = that.page + 1;
                that.finished = false;
              } else {
                that.finished = true;
                //that.showHint = false;
              }
            }
          } else {
            that.finished = true;
            this.$Toast(!ret ? this.$t("lang.queryfail") : ret.message);
          }
        }
      );
    },

    onLoad() {
      this.Query();
    },
  },
};
</script>
