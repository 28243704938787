var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('MyHeader',{attrs:{"title":_vm.$t('lang.menu_missrecord'),"ShowBack":true,"BackUrl":"/query/index"}}),_c('van-list',{attrs:{"finished":_vm.finished,"finished-text":_vm.$t('lang.listend'),"immediate-check":false,"direction":"down"},on:{"load":_vm.onLoad},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},_vm._l((_vm.lstData),function(mData){return _c('van-cell',{key:mData.id,class:mData.isMiss
          ? mData.endTime > new Date()
            ? 'isWait'
            : 'isMiss'
          : mData.isLate || mData.isEarly
          ? 'warning'
          : '',attrs:{"title":mData.placeName,"is-link":"","icon":mData.isMiss || mData.isLate || mData.isEarly ? 'warning' : '',"to":{
        name: 'PollingPlanDetail',
        params: {
          id: mData.id,
          title: mData.placeName,
          isMiss: mData.isMiss,
          memo: mData.comment,
          isaudit: mData.isAudit,
        },
      }}},[_c('template',{slot:"label"},[_c('span',[_vm._v("巡检时间："+_vm._s(mData.isMiss ? "【" + (mData.endTime > new Date() ? _vm.$t("lang.notstarted") : _vm.$t("lang.miss")) + "】" : mData.arriveTime != undefined ? _vm.$Tools.FormatDate1(mData.arriveTime) : ""))]),_c('div',{staticStyle:{"display":"block"}},[_c('span',{staticStyle:{"display":"block"}},[_vm._v("线路/人员："+_vm._s(mData.routeName + (mData.inspectorName != undefined ? " " + mData.inspectorName : ""))),_c('br'),_vm._v(" 开始时间："+_vm._s(_vm.$Tools.FormatDate1(mData.startTime))),_c('br'),_vm._v(" 结束时间："+_vm._s(_vm.$Tools.FormatDate1(mData.endTime)))])])])],2)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }